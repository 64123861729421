import './App.css';
import MainSection from './Components/MainSection/MainSection.js'
import Section2 from './Components/Section2/Section2.js'
import Section3 from './Components/Section3/Section3.js'
import Section4 from './Components/Section4/Section4.js'
import  WordpressPage  from './Components/WordpressPage/WordpressPage.js'
import { BrowserRouter as Router , Route } from 'react-router-dom'
import Navbar from './Components/Navbar/Navbar.js'
import Footer from './Components/Footer/Footer.js'
import Sidebar from './Components/Sidebar/Sidebar.js'
import $ from "jquery";


function App() {


  $(document).on("click", "a[href^=\\#]", function () {
    $("html, body").animate(
      {
        scrollTop: $(
          'section[data-target="' + this.hash.slice(1) + '"]'
        ).offset().top,
      },
      500
    );
    return false;
  });




  return (
    <Router>
    <div id='app-main-container'>

    <section className="one" data-target="one">
        <MainSection />
      </section>

      <section className="two" data-target="two">
        <Section2 />
      </section>

      <section className="three" data-target="three">
        <Section3 />
      </section>

      <section className="four" data-target="four">
        <Section4 />
      </section>


  <Sidebar />






<Navbar className='news' onClick={()=>{$('.news').hide()}} />

    {/* <Route exact path="/" component={Homepage} /> */}
    <Route path="/WordpressPage" component={WordpressPage} />


<Footer/>

    </div>
    </Router>
  );
}

export default App;
