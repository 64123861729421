export const techStackImages = [
     { img: "ProgrammingLanguagesIcons/ReactIcon.png" , datatip: 'Hooks/Redux/Context' },
     { img: "ProgrammingLanguagesIcons/nextPNG.png" },
     { img: "ProgrammingLanguagesIcons/TSpng.png" },
     { img: "ProgrammingLanguagesIcons/JS-Icon.png" },
     { img: "ProgrammingLanguagesIcons/HTML5-Icon.png" },
     { img: "ProgrammingLanguagesIcons/CSS3Icon.png" },
     { img: "ProgrammingLanguagesIcons/Sass-Icon.png" },
     { img: "ProgrammingLanguagesIcons/ReactNativePNG.png" },
     { img: "ProgrammingLanguagesIcons/NodeJS-Icon-White.png" , datatip: 'RESTful API, PassportJS, JWT, SSR(EJS)' },
     { img: "ProgrammingLanguagesIcons/ExpressJS.png" },
     { img: "ProgrammingLanguagesIcons/MongoDBicon.png" },
     { img: "ProgrammingLanguagesIcons/PostgresPNG.png" },
     { img: "ProgrammingLanguagesIcons/phpPNG.png" },
     { img: "ProgrammingLanguagesIcons/jQueryLogo.png" },
     { img: "ProgrammingLanguagesIcons/awsPNG.png" , datatip: 'Amazon Web Services' },

   ];

 
export const toolsAndMiscImages = [
     { img: "ToolsAndMiscImages/Git-Icon-White.png"},
     { img: "ToolsAndMiscImages/GithubLogo.png" },
     { img: "ToolsAndMiscImages/VSCodeLogo.png" },
     { img: "ToolsAndMiscImages/FirebaseLogo.png" },
     { img: "ToolsAndMiscImages/Heroku-Logo.png" },
     { img: "ToolsAndMiscImages/Netlify-logo.png" },
     { img: "ToolsAndMiscImages/PhotoshopCCLogo.png" },
     { img: "ToolsAndMiscImages/figmaPNG.png" },
     { img: "ToolsAndMiscImages/WordpressPNG.png" } ,
     { img: "ToolsAndMiscImages/PostmanLogo.png" }
   ];
 