import "./MainSectionStyle.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";

function MainSection() {
  useEffect(() => {
    Aos.init({ duration: 3500 });
  }, []);

  return (
    <div className="main-section-main-cotainer">
      <div id="backgroundContainer">
        <div className="main-section-content">
          <div id="main-section-headers-btns-container">
            <span className="main-header-part1" data-aos="fade-up">
              Hi , I'm Matan , and <br />
            </span>
            <span
              className="main-header-part2"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              I build <span className="glowingText">&</span> design custom
              websites.
            </span>
            <br />
            <span
              className="main-header-part3"
              data-aos="fade-up"
              data-aos-delay="1500"
            >
              Together , we’ll create a{" "}
              <span className="glowingText">breathtaking</span> digital presence
              for your clients!
            </span>

            <div className="main-section-content-btn-container">
              <a href="#three">
                <button
                  className="main-section-btns"
                  id="btn-1"
                  data-aos="fade-right"
                  data-aos-delay="2000"
                >
                  MY PORTFOLIO
                </button>
              </a>
              <a href="#four">
                <button
                  className="main-section-btns"
                  id="btn-2"
                  data-aos="fade-left"
                  data-aos-delay="2000"
                >
                  GET IN TOUCH
                </button>
              </a>
            </div>
          </div>
        </div>
        <div id="main-section-arrow-container">
          <a id="arrow-href1" href="#two"></a>
        </div>
      </div>
    </div>
  );
}

export default MainSection;
