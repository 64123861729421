import React from "react";
import "./Section4Style.css";
import emailjs from 'emailjs-com'


function Section4() {

  const sendEmail = (e) =>{
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_h0j92t4', e.target, 'user_Gx9YFdtSDzufNybJKLXsF')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
       alert("The message has been sent! , Thank you!")

  }

  return (
    <div className="section-4-maindiv">
      <div id="d"></div>

      <div id="section-4-main-content-parent">
        <form id="section-4-content-chiled-form" onSubmit={sendEmail}>
          <p>Let's Talk</p>
          <span id='form-instructions'>
            Please, use the form below or send an email to matan2288
            <span className='red-font'>@</span>gmail<span className='red-font'>.</span>com
          </span>
          <input
            className="section-4-inputs"
            placeholder="Name"
            name="name"
            required
          />

          <input
            className="section-4-inputs"
            placeholder="Your Email"
            name="email"
            type="email"
            required
          />

          <textarea
            className="section-4-inputs"
            placeholder="Message"
            name="message"
            required
          />

          <div id="form-btn-container">
            <button id="form-btn">Submit</button>
          </div>
        </form>
      </div>

      <div id="arrow-container-s4">
        <a href="#one" className="scroll-arrow-top"></a>
      </div>
    </div>
  );
}

export default Section4;
