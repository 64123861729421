import React from "react";
import "./sideBarStyle.css";

function sideBar() {
  return (
    <div id="sideBar-maindiv">
      <div id="container">
        <a className="sidebar-social-networks-icons" 
        id="linkedin-sd"
        href="https://www.linkedin.com/in/matan-elmaliach-30020b196"
        target="_blank"
        ></a>
        <a className="sidebar-social-networks-icons"
         id="github-sd"
         href="https://github.com/matan2288/"
         target="_blank"
         ></a>
        <a className="sidebar-social-networks-icons" 
        id="codepen-sd"
        href="https://codepen.io/matan-elmaliach/"
        target="_blank"
        ></a>
      </div>
    </div>
  );
}

export default sideBar;
