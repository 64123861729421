export const myProjects = [
     {
       img: "ProjectsImages/BankingScreensApp.png",
       title: "Banking Screens App",
       technoligy1: 'ProgrammingLanguagesIcons/ReactIcon.png' ,
       technoligy2: "ProgrammingLanguagesIcons/NodeJS-Icon-White.png",
       technoligy3: "ProgrammingLanguagesIcons/passportjsPNG.png",
       technoligy4: "ProgrammingLanguagesIcons/MongoDBicon.png ",
       technoligy5: 'ProgrammingLanguagesIcons/Sass-Icon.png',
       technoligiesDescription:
         "Built using MERN Stack, PassportJS Auth, Protected Routes, useContext+UseReducer hooks ",
       appDescription:
         "An app which authenticates the user via the registerd email or google accounts And sends the users's data to the database, The app can also reset the user's password! ",
         viewSource: "https://github.com/matan2288/bankingscreensapp-5329" ,
         tryItLive: "https://github.com/matan2288/bankingscreensapp-5329" ,
         displayLiveBtn: "none"
     },
     {
       img: "ProjectsImages/ReactNativeTodoListAppImg.png",
       title: "Todo App(React Native)",
       technoligy1: "",
       technoligy2: 'ProgrammingLanguagesIcons/ReactNativePNG.png' ,
       technoligy3: "ProgrammingLanguagesIcons/ReactNativeNavigationLogoPng.png",
       technoligy4: "ProgrammingLanguagesIcons/CSS3Icon.png",
       technoligy5: "",
       technoligiesDescription:
         "Built using React Native, React Navigation, Async Storage, CSS3 ",
       appDescription:
         "React native todo list CRUD app which saves the user's info to your local smartphone storage(via Async Storage).",
         viewSource: "https://github.com/matan2288/rn-todolist-app643" ,
         tryItLive: "https://github.com/matan2288/rn-todolist-app643" ,
         displayLiveBtn: "none"
     },
     {
       img: "ProjectsImages/EtherscanAccountTrackerElectronApp.png",
       title: "Etherscan Tracker ",
       technoligy1: "",
       technoligy2: 'ProgrammingLanguagesIcons/ReactIcon.png' ,
       technoligy3: "ProgrammingLanguagesIcons/ElectronLogo.png",
       technoligy4: "ProgrammingLanguagesIcons/CSS3Icon.png",
       technoligy5: "",
       technoligiesDescription:
         "Built using React Hooks, Electron, CSS3 + Bootstrap ",
       appDescription:
         "A Simple React App that tracks https://etherscan.io Users transactions data (Can also run with Electron! Instructions are included in the github repo!) ",
         viewSource: "https://github.com/matan2288/EtherscanAccount-TransactionTracker-electron-reactapp" ,
         tryItLive: "https://pensive-pike-966bd7.netlify.app/",
         displayLiveBtn: "block"
     },
     {
       img: "ProjectsImages/MemoriesAppImage.png",
       title: "Memories App",
       technoligy1: 'ProgrammingLanguagesIcons/ReactIcon.png' ,
       technoligy2: "ProgrammingLanguagesIcons/NodeJS-Icon-White.png",
       technoligy3: "ProgrammingLanguagesIcons/ExpressLogo.png",
       technoligy4: "ProgrammingLanguagesIcons/MongoDBicon.png ",
       technoligy5: "ProgrammingLanguagesIcons/CSS3Icon.png",
       technoligiesDescription:
         "Built using React Hooks + useContext, Node + Express, MongoDB, CSS3 + Material UI ",
       appDescription:
         "A Full-stack App (MERN Stack) which allows you to store each memory into a remote database!",
         viewSource: "https://github.com/matan2288/Memories-MERN-APP" ,
         tryItLive: "https://reverent-perlman-db7186.netlify.app/",
         displayLiveBtn: "block"
     },
     {
       img: "ProjectsImages/WeatherApp.png",
       title: "Weather App",
       technoligy1: '' ,
       technoligy2: 'ProgrammingLanguagesIcons/ReactIcon.png',
       technoligy3: 'ProgrammingLanguagesIcons/Sass-Icon.png' ,
       technoligy4: 'ProgrammingLanguagesIcons/CSS3Icon.png',
       technoligy5: '""',
       technoligiesDescription:
         "Built using React Hooks + Axios HTTP Requests , CSS",
       appDescription:
         "A Simple Weather-App which shows you the city weather data you searched for from a live API link.",
         viewSource: "https://github.com/matan2288/Weather-App/tree/master/Weather-App-Source" ,
         tryItLive: "https://matan2288.github.io/Weather-App/",
         displayLiveBtn: "block"
     },
     {
       img: "ProjectsImages/SudokoImage.png",
       title: "Sudoko Game",
       technoligy1: "",
       technoligy2: "ProgrammingLanguagesIcons/HTML5-Icon.png",
       technoligy3: "ProgrammingLanguagesIcons/CSS3Icon.png",
       technoligy4: "ProgrammingLanguagesIcons/JS-Icon.png",
       technoligy5: "",
       technoligiesDescription: "Built using HTML5, CSS3, Javascript ES6",
       appDescription: "A Fully functioning Vanilla JS sudoko game , give it a try! ,  You'll love it!.",
       viewSource: "https://github.com/matan2288/JS-Sudoko" ,
       tryItLive: "https://codepen.io/matan-elmaliach/pen/poEgxKZ",
       displayLiveBtn: "block"
     },
     {
       img: "ProjectsImages/CalculatorImage.png",
       title: "Calculator",
       technoligy1: "",
       technoligy2: "ProgrammingLanguagesIcons/HTML5-Icon.png",
       technoligy3: "ProgrammingLanguagesIcons/CSS3Icon.png",
       technoligy4: "ProgrammingLanguagesIcons/JS-Icon.png",
       technoligy5: "",
       technoligiesDescription: "Built using HTML5, CSS3, Javascript ES6",
       appDescription: "Clean and simple Vanilla JS Calculator.",
       viewSource: "https://github.com/matan2288/JS-Calculator" ,
       tryItLive: "https://codepen.io/matan-elmaliach/pen/WNxVBax",
       displayLiveBtn: "block"
     },
     {
       img: "ProjectsImages/TodoImage.png",
       title: "Todo List App",
       technoligy1: "",
       technoligy2: "ProgrammingLanguagesIcons/HTML5-Icon.png",
       technoligy3: "ProgrammingLanguagesIcons/CSS3Icon.png",
       technoligy4: "ProgrammingLanguagesIcons/JS-Icon.png",
       technoligy5: "",
       technoligiesDescription: "Built using HTML5, CSS3, Javascript ES6",
       appDescription: "Struggle finding the right Todo App? , You found the right place ! ",
       viewSource: "https://github.com/matan2288/TodoList-App" ,
       tryItLive: "https://codepen.io/matan-elmaliach/details/eYzqaLG",
       displayLiveBtn: "block"
     },
 
 
   ];