import React from "react";
import "./Section2Style.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import {
  techStackImages,
  toolsAndMiscImages,
} from "./technoligiesObjectsHolder.js";
import ReactTooltip from "react-tooltip";

function Section2() {
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);

  return (
    <div className="section-2-maindiv">
      <div id="section2-contentbox-parent">
        <ReactTooltip />

        <div id="section2-contentbox-chiled">
          <div
            className="section2-content-container-chiled-1"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h4>Who am I?</h4>
            <p>
              My name is Matan, and I am a Frontend/Fullstack web developer
              based in Israel. I am a fullstack bootcamp graduate from a local
              college, but mostly I am self taught. For the past year (with a
              little help from Covid lockdowns) I've been coding almost non-stop
              to continuously sharpen my skills. Fueled by enthusiasm and
              motivation for this new and exciting field, I am ready to take on
              any challenge!
            </p>

            <a
              href="https://drive.google.com/file/d/1y0DeyKJ3EIZ5xvfoKPSeXt7_FNv0Gyc6/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="section2-resume-btn"
                id="btn-1"
                data-aos="fade-in"
                data-aos-delay="500"
              >
                View Resume
              </button>
            </a>
            <div
              id="section2-profile-img"
              data-aos="zoom-in"
              data-aos-delay="800"
            ></div>
          </div>

          <p id="content2-title2" data-aos="fade-in" data-aos-delay="750">
            My Skills
          </p>

          <div id="section2-content-container" data-aos="fade-up">
            <div
              className="section2-content-container-chiled"
              data-aos="fade-right"
              data-aos-delay="1000"
            >
              <div id="section2-techStack-container">
                <h4>My Tech Stack</h4>
                <p>
                  I really enjoy solving problems by using these various
                  technnologies, as well as making things easy to use and
                  AMAZING to look at. I can't stop learning new things, the more
                  the merrier!
                </p>

                {techStackImages.map((techStackList, index) => (
                  <img
                    className="techStack-children"
                    src={techStackList.img}
                    data-tip={techStackList.datatip}
                    alt=""
                    key={index}
                  />
                ))}
              </div>
            </div>

            <div
              className="section2-content-container-chiled"
              data-aos="fade-left"
              data-aos-delay="1000"
            >
              <h4>Tools & Utilities</h4>

              <div id="section2-techStack-container">
                <p>
                  "A good tool improves the way you work. A great tool improves
                  the way you think". These are just a few more great tools that
                  helps me to imporove my overall workflow & get the job done.
                </p>

                {toolsAndMiscImages.map((toolsAndMiscImagesList, index) => (
                  <img
                    className="techStack-children"
                    src={toolsAndMiscImagesList.img}
                    // id={`toolsAndMiscImagesListID${index}`}
                    data-tip={toolsAndMiscImagesList.datatip}
                    alt=""
                    key={index}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="section-2-img-arrow-container">
            <a id="arrow-href2" href="#three"></a>
          </div>
        </div>
      </div>

      <div id="c"></div>
    </div>
  );
}

export default Section2;
