import React from "react";
import "./navbar.css";
import $ from "jquery";

function Navbar() {
  return (
    <div className="navbar-maindiv navbar-maindiv-active">
      <div id="navbar-hamburger">
        <div className="dropDown-wrapper">
          <input type="checkbox" id="dropDown-toggle" />
          <label htmlFor="dropDown-toggle" className="dropDown-toggle-label">
            <div
              className="menu-wrapper"
              onClick={() => {
                $(".hamburger-menu").toggleClass("animate");
                $(".navbar-maindiv").toggleClass("navbar-maindiv-active");
                $(".navbar-logo").toggleClass("navbar-logo-active");
              }}
            >
              <div className="hamburger-menu"></div>
            </div>
          </label>
          <div className="dropDown">
            <input type="radio" id="option-1" name="options" />
               
            <a href="#two" className="menu-navigation">
              <label htmlFor="option-1">About Me</label>{" "}
            </a>
            <input type="radio" id="option-2" name="options" />
            <a href="#three" className="menu-navigation">
              <label htmlFor="option-1">My Work</label>{" "}
            </a>
            <input type="radio" id="option-3" name="options" />
            <a href="#four" className="menu-navigation">
              <label htmlFor="option-1">Contact Me</label>{" "}
            </a>
            <input type="radio" id="option-4" name="options" />
            <a href="#one" className="menu-navigation">
              <label htmlFor="option-1">Back To Top</label>{" "}
            </a>





          </div>
        </div>
      </div>



      <div className="navbar-logo">
        ME<span style={{ fontWeight: "bolder" }}>WEBDEV</span>
      </div>

      
    </div>
  );
}

export default Navbar;
