import React from 'react';
import './Section3Style.css';
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { myProjects } from './projectsObjectHolder.js'

function Section3() {



  useEffect(()=>{

    Aos.init({duration: 1500});
    
     } , [])
  return (
    <div className="section-3-maindiv">
      <h1 id="section3-h1" data-aos="slide-up">These are my projects</h1>
      <div id="section-3-main-content-parent">
        <div className="section-3-card-container">
          {myProjects.map(( myProjectsMapped , index ) => (
            <div 
              data-aos="fade-up"
              data-aos-delay="500"
              className="section-3-card-div"
              style={{ backgroundImage: `url(${myProjectsMapped.img})` }}
              key={index}>
                
              <div className="overlay-main-container" key={index}>
                <div className="overlay-title">
                  <p>{myProjectsMapped.title}</p> 
                </div>

                <div className="overlay-technoligiesImages-container">

                  <span
                    className="overlay-technoligiesImages"
                    style={{ backgroundImage: `url(${myProjectsMapped.technoligy1})` }}
                  ></span>
                  <span
                    className="overlay-technoligiesImages"
                    style={{ backgroundImage: `url(${myProjectsMapped.technoligy2})` }}
                  ></span>
                  <span
                    className="overlay-technoligiesImages"
                    style={{ backgroundImage: `url(${myProjectsMapped.technoligy3})` }}
                  ></span>
                  <span
                    className="overlay-technoligiesImages"
                    style={{ backgroundImage: `url(${myProjectsMapped.technoligy4})` }}
                  ></span>
                                    <span
                    className="overlay-technoligiesImages"
                    style={{ backgroundImage: `url(${myProjectsMapped.technoligy5})` }}
                  ></span>
                </div>

                <div className="overlay-technoligiesDescription">
                  {myProjectsMapped.technoligiesDescription}
                </div>
                <div className="overlay-appDescription">
                  {myProjectsMapped.appDescription}
                </div>

                <div className="overlay-btn-container">

                  <button className="overlay-btns" id="overlay-bt1">
                  <a href={myProjectsMapped.viewSource}  target="_blank" rel="noreferrer">
                    View Source
                    </a>
                  </button>

                  <button className="overlay-btns" id="overlay-bt2" style={{display:`${myProjectsMapped.displayLiveBtn}`}}>
                  <a href={myProjectsMapped.tryItLive} target="_blank" rel="noreferrer">
                    Try It Live
                    </a>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div id="arrow-container-s3">
        <a id="arrow-href" href="#four"> </a>
      </div>
    </div>
  );
}

export default Section3;
