import React from "react";
import "./FooterStyle.css";

function Footer() {

  return (
    <div className="footer-maindiv">
      <div id="social-networks-container">
        <a
          className="footer-social-networks-icons"
          id="linkedin-logo"
          href="https://www.linkedin.com/in/matan-elmaliach-30020b196"
          target="_blank"
        ></a>

        <a
          className="footer-social-networks-icons"
          id="github-logo"
          href="https://github.com/matan2288/"
          target="_blank"
        ></a>

        <a
          className="footer-social-networks-icons"
          id="codepen-logo"
          href="https://codepen.io/matan-elmaliach/"
          target="_blank"
        ></a>
      </div>

      <span id="Footer">Made by Matan Elmaliach 2021 ✌.</span>
    </div>
  );
}

export default Footer;
