import React from 'react'
import './WordpressPage.css'
import {WordpressSection} from './WordpressSection.js'

function WordpressPage() {
     return (
          <div className='WordpressPage-maindiv' >
          <WordpressSection/>
     </div>
     )
}

export default WordpressPage
